import { useMemo } from 'react';
import { LONG_DISTANCE } from '../helpers/calculateDistance';
import { distanceOfContractorToServiceArea } from '../helpers/distanceOfContractorToServiceArea';
import useUsers from '../hooks/useUsers';
import { WithID } from '@shared/types/withID';
import AddressInterface from '@shared/types/address';
import Role from '@shared/types/role';

import UserInterface from '@shared/types/user';

interface Props {
  roles?: string[];
  skillID?: string;
  address?: AddressInterface;
  excludeUsers?: string[];
  onlyServiceArea?: boolean;
  agency?: string;
}

interface ReturnType {
  id: string;
  user: WithID<UserInterface>;
  distance: number;
}

export const useUserList = (params: Props): ReturnType[] => {
  const { roles, skillID, address, excludeUsers, onlyServiceArea, agency } =
    params;
  const filters = useMemo(
    () => ({
      roles,
      isActive: true,
      skillID,
      address,
      excludeUsers,
      agency,
    }),
    [address, excludeUsers, roles, skillID, agency],
  );

  const [users] = useUsers(filters);

  const values: ReturnType[] = useMemo(() => {
    const l: ReturnType[] = [];
    Object.keys(users).forEach((key) => {
      const user = users[key];
      if (!user) {
        return;
      }
      if (!address) {
        l.push({
          id: user._id,
          user: user,
          distance: 0,
        });
      } else if (user.role === Role.CONTRACTOR) {
        const distance = distanceOfContractorToServiceArea(
          user,
          address,
          onlyServiceArea,
        );
        if (distance === false) {
          return;
        }
        l.push({
          id: user._id,
          user: user,
          distance: distance === true ? LONG_DISTANCE : distance,
        });
      } else {
        l.push({
          id: user._id,
          user: user,
          distance: user.distance || LONG_DISTANCE,
        });
      }
    });
    return l;
  }, [users, address, onlyServiceArea]);
  return values;
};
