import Role from '@shared/types/role';
import SelectValueInterface from './selectValue';

enum TaskStatuses {
  CREATED = 'CREATED',
  SUBTASK_CREATED = 'SUBTASK_CREATED',
  COMPLETED = 'COMPLETED',
  QUOTED = 'QUOTED',
  STARTED = 'STARTED',
  DONE = 'DONE',
  INVOICED = 'INVOICED',
  CANCELLED = 'CANCELLED',
  QUOTE_SENT_AND_CANCELLED = 'QUOTE_SENT_AND_CANCELLED',
  UPFRONT_ACCEPTED = 'UPFRONT_ACCEPTED',
  UPFRONT_INVOICED = 'UPFRONT_INVOICED',
}
export default TaskStatuses;

type StatusOptionInterface = SelectValueInterface & {
  isActive: boolean | null;
};

export const StatusOptions: StatusOptionInterface[] = [
  { isActive: null, label: 'All Statuses', value: '' },
  { isActive: true, label: 'CREATED', value: TaskStatuses.CREATED },
  {
    isActive: true,
    label: 'JOB CREATED',
    value: TaskStatuses.SUBTASK_CREATED,
  },

  { isActive: true, label: 'QUOTED', value: TaskStatuses.QUOTED },
  {
    isActive: true,
    label: 'UPFRONT ACCEPTED',
    value: TaskStatuses.UPFRONT_ACCEPTED,
  },
  {
    isActive: true,
    label: 'UPFRONT INVOICED',
    value: TaskStatuses.UPFRONT_INVOICED,
  },
  { isActive: true, label: 'STARTED', value: TaskStatuses.STARTED },
  { isActive: true, label: 'DONE', value: TaskStatuses.DONE },
  { isActive: false, label: 'INVOICED', value: TaskStatuses.INVOICED },
  { isActive: false, label: 'COMPLETED', value: TaskStatuses.COMPLETED },
  { isActive: false, label: 'CANCELLED', value: TaskStatuses.CANCELLED },
  {
    isActive: false,
    label: 'QUOTE SENT AND CANCELLED',
    value: TaskStatuses.QUOTE_SENT_AND_CANCELLED,
  },
];

const StatusOptionsOwner: StatusOptionInterface[] = [
  { isActive: null, label: 'All Statuses', value: '' },
  { isActive: true, label: 'JOB CREATED', value: TaskStatuses.CREATED },
  {
    isActive: true,
    label: 'JOB CREATED',
    value: TaskStatuses.SUBTASK_CREATED,
  },

  { isActive: true, label: 'QUOTE SENT', value: TaskStatuses.QUOTED },
  {
    isActive: true,
    label: 'QUOTE ACCEPTED',
    value: TaskStatuses.UPFRONT_ACCEPTED,
  },
  {
    isActive: true,
    label: 'AWAITING PAYMENT',
    value: TaskStatuses.UPFRONT_INVOICED,
  },
  { isActive: true, label: 'WORKS IN PROGRESS', value: TaskStatuses.STARTED },
  { isActive: true, label: 'WORKS COMPLETE', value: TaskStatuses.DONE },
  { isActive: true, label: 'AWAITING PAYMENT', value: TaskStatuses.INVOICED },
  { isActive: false, label: 'JOB COMPLETE', value: TaskStatuses.COMPLETED },
  { isActive: false, label: 'CANCELLED', value: TaskStatuses.CANCELLED },
  {
    isActive: false,
    label: 'QUOTE CANCELLED',
    value: TaskStatuses.QUOTE_SENT_AND_CANCELLED,
  },
];

const StatusOptionsMember: StatusOptionInterface[] = [
  { isActive: true, label: 'CREATED', value: TaskStatuses.CREATED },
  {
    isActive: true,
    label: 'JOB CREATED',
    value: TaskStatuses.SUBTASK_CREATED,
  },

  { isActive: true, label: 'QUOTED', value: TaskStatuses.QUOTED },
  {
    isActive: true,
    label: 'UPFRONT ACCEPTED',
    value: TaskStatuses.UPFRONT_ACCEPTED,
  },
  {
    isActive: true,
    label: 'UPFRONT INVOICED',
    value: TaskStatuses.UPFRONT_INVOICED,
  },
  { isActive: true, label: 'STARTED', value: TaskStatuses.STARTED },
  { isActive: true, label: 'DONE', value: TaskStatuses.DONE },
  { isActive: null, label: 'All Statuses', value: '' },
];

export function getStatusOptions(role?: Role): StatusOptionInterface[] {
  if (role === Role.OWNER) {
    return StatusOptionsOwner;
  }
  if (role === Role.MEMBER) {
    return StatusOptionsMember;
  }

  return StatusOptions;
}

export function getTaskStatusLabel(value?: string, role?: Role): string {
  return (
    getStatusOptions(role).find((val) => val.value === value)?.label ||
    value ||
    ''
  );
}
