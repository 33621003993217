import { createContext, PropsWithChildren, useMemo } from 'react';
import SkillInterface from '@shared/types/skill';
import useAllData from '../hooks/useAllData';
import { SKILLS_COLLECTION } from '../constants/collections';
import _ from 'lodash';
import { WithID } from '@shared/types/withID';

type ContextType = {
  skills: Record<string, WithID<SkillInterface>>;
};

export const SkillsContext = createContext<ContextType>({
  skills: {},
});

const SkillsWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const skills = useAllData<SkillInterface>(SKILLS_COLLECTION);

  const cleanedSkills = useMemo(() => {
    if (!skills) {
      return {};
    }
    const map: Record<string, WithID<SkillInterface>> = {};
    _.toPairs(skills).forEach(([id, skill]) => {
      if (skill) {
        map[id] = skill;
      }
    });
    return map;
  }, [skills]);
  if (!skills) {
    return null;
  }
  return (
    <SkillsContext.Provider value={{ skills: cleanedSkills }}>
      {children}
    </SkillsContext.Provider>
  );
};

export default SkillsWrapper;
