import { createContext } from 'react';
import Role from '@shared/types/role';
import TaskEditContextInterface from '../interfaces/TaskEditContextInterface';
import TaskStatuses from '../interfaces/taskStatuses';

const TaskEditContext = createContext<TaskEditContextInterface>({
  id: '',
  task: { description: '', status: TaskStatuses.CREATED, propertyID: '' },
  property: { id: '', ownerID: '', isActive: false },
  owner: {
    displayName: '',
    email: '',
    role: Role.OWNER,
    disabled: true,
    phoneNumber: '',
  },
  subtasks: {},
});
export default TaskEditContext;
