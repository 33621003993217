import AddressInterface from '@shared/types/address';

import UserInterface from '@shared/types/user';
import { calculateDistance, LONG_DISTANCE } from './calculateDistance';

export const distanceOfContractorToServiceArea = (
  user: UserInterface,
  address: AddressInterface,
  onlyServiceArea?: boolean,
): false | true | number => {
  const sendLeadsOutsideOfCoverageArea = onlyServiceArea
    ? false
    : user.sendLeadsOutsideOfCoverageArea === false
      ? false
      : true;

  console.log({ sendLeadsOutsideOfCoverageArea });
  const postArea = (address.postCode?.split(' ')[0] || '').toLocaleLowerCase();
  if (
    user.excludedServiceAreas
      ?.map((a) => a.toLocaleLowerCase())
      .includes(postArea)
  ) {
    return false;
  }

  const distances: number[] = [];
  const addresses = [...(user.serviceAddresses || [])];
  if (
    user.contactAddress &&
    user.contractAddressIsIncludedInServiceArea !== false
  ) {
    addresses.push({
      address: user.contactAddress,
      radius: 15,
    });
  }

  addresses.forEach((serviceAddr) => {
    const serviceAddrDistance = calculateDistance(serviceAddr.address, address);
    if (
      sendLeadsOutsideOfCoverageArea !== false ||
      serviceAddrDistance < serviceAddr.radius
    ) {
      console.log('Service Area Check', true, user.displayName, {
        address,
        serviceAddr,
      });
      distances.push(serviceAddrDistance);
    } else {
      console.log('Service Area Check', false, user.displayName, {
        address,
        serviceAddr,
      });
    }
  });
  console.log(user.displayName, { user, distances });
  if (distances.length === 0) {
    return sendLeadsOutsideOfCoverageArea;
  }
  const minimumDistance = distances.reduce(
    (prev, curr) => Math.min(prev, curr),
    LONG_DISTANCE,
  );
  return minimumDistance;
};
