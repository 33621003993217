import React, { useMemo } from 'react';
import { LONG_DISTANCE } from '../../helpers/calculateDistance';
import { useUserList } from '../../hooks';
import AddressInterface from '@shared/types/address';
import Role from '@shared/types/role';

import UserInterface from '@shared/types/user';
import FormAutoFill from '../formComponents/formAutoFill';

interface Props {
  onChange: (userID: string) => void;
  val: string;
  label: string;
  showEmpty?: boolean;
  emptyLabel?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  roles?: string[];
  skillID?: string;
  address?: AddressInterface;
  excludeUsers?: string[];
  className?: string;
  customLabel?: (user: UserInterface, distance?: number) => string;
  showClear?: boolean;
  agency?: string;
}

const UserSelector: React.FC<Props> = (props) => {
  const {
    onChange,
    val,
    disabled,
    showEmpty,
    emptyLabel,
    label,
    fullWidth,
    roles,
    skillID,
    address,
    excludeUsers,
    className,
    customLabel,
    showClear,
    agency,
  } = props;

  const users = useUserList({
    roles,
    skillID,
    address,
    excludeUsers,
    onlyServiceArea: false,
    agency,
  });
  const formatedUsers = useMemo(() => {
    return users.map((item) => {
      const { user, id, distance } = item;
      const ratingText = user.rating ? ` - ${user.rating} ⭐` : '';
      if (!address) {
        return {
          distance: 0,
          value: id,
          label: customLabel
            ? customLabel(user)
            : `${user.displayName || ''}${ratingText}`,
          key: id,
        };
      } else if (user.role === Role.CONTRACTOR) {
        return {
          distance: distance,
          value: id,
          label: customLabel
            ? customLabel(user, distance)
            : `${user.displayName || ''} - ${distance} miles ${ratingText}`,
          key: id,
        };
      } else {
        return {
          distance: user.distance || LONG_DISTANCE,
          value: id,
          label: customLabel
            ? customLabel(user)
            : `${user.displayName || ''} - ${distance} miles${ratingText}`,
          key: id,
        };
      }
    });
  }, [address, customLabel, users]);
  const sortedUsers = useMemo(() => {
    const l = [...formatedUsers];
    if (address) {
      l.sort((a, b) => {
        return a.distance - b.distance;
      });
    } else {
      l.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
    }
    if (showEmpty) {
      l.unshift({
        distance: 0,
        value: '',
        label: emptyLabel || '',
        key: 'EMPTY',
      });
    }
    return l;
  }, [address, emptyLabel, formatedUsers, showEmpty]);

  if (!users) {
    return null;
  }

  return (
    <FormAutoFill
      showClear={showClear}
      disabled={disabled}
      val={sortedUsers.find((a) => a.value === val) ? val : ''}
      values={sortedUsers}
      onChange={onChange}
      label={label}
      fullWidth={fullWidth}
      className={className}
    />
  );
};
export default UserSelector;
