import { Action } from 'redux';

export enum ActionEnum {
  'SET_POPUP' = '@@Popup_Reducer_SET_POPUP',
  'REMOVE_POPUP' = '@@Popup_Reducer_REMOVE_POPUP',
  'REMOVE_ALL_POPUP' = '@@Popup_Reducer_REMOVE_ALL_POPUP',
}

export enum PopupType {
  'CHANGE_PASSWORD' = 'CHANGE_PASSWORD',
  'SHOW_MESSSAGE' = 'SHOW_MESSSAGE',
  'CREATE_SKILL' = 'CREATE_SKILL',
  'CREATE_USER' = 'CREATE_USER',
  'CREATE_PROPERTY' = 'CREATE_PROPERTY',
  'CHANGE_OTHERS_PASSWORD' = 'CHANGE_OTHERS_PASSWORD',
  'FILE' = 'FILE',
  'CREATE_TASK' = 'CREATE_TASK',
  'CREATE_SUBTASK' = 'CREATE_SUBTASK',
  'RENAME_FILE' = 'RENAME_FILE',
  'POSTCODE_SELECTOR' = 'POSTCODE_SELECTOR',
  'MAP' = 'MAP',
  'LOADING' = 'LOADING',
  'CHANGE_TASK_STATUS' = 'CHANGE_TASK_STATUS',
  'CHANGE_TASK_ASSIGNEE' = 'CHANGE_TASK_ASSIGNEE',
  'CREATE_INVITATION' = 'CREATE_INVITATION',
  'QUOTE_INVITATION' = 'QUOTE_INVITATION',
  'UPDATE_JOB_SHEET' = 'UPDATE_JOB_SHEET',
  'PREVIEW_JOB_SHEET' = 'PREVIEW_JOb_SHEET',
  'DELETE_INVITATION' = 'DELETE_INVITATION',
  'INVITATION_REQUEST' = 'INVITATION_REQUEST',
  'PREVIEW_SUBTASK_JOB_SHEET' = 'PREVIEW_SUBTASK_JOB_SHEET',
  'CHANGE_SUBTASK_STATUS' = 'CHANGE_SUBTASK_STATUS',
  'SEND_SMS' = 'SEND_SMS',
  'SUBTASK_INVOTED' = 'SUBTASK_INVOTED',
  'CALCULATE_PROFIT' = 'CALCULATE_PROFIT',
  'SUBTASK_TRIGGER_REMINDER_MAIL' = 'SUBTASK_TRIGGER_REMINDER_MAIL',
  'CONTRACTOR_AVAILIABILITY' = 'CONTRACTOR_AVAILIABILITY',
  'CHANGE_TASK_BOOKER' = 'CHANGE_TASK_BOOKER',
}
export interface PopupState {
  arr: { type?: PopupType; args?: { [key: string]: any } }[];
}
export interface SetPopupAction extends Action {
  type: ActionEnum.SET_POPUP;
  args: { type: PopupType; args?: { [key: string]: any } };
}
export interface RemovePopupAction extends Action {
  type: ActionEnum.REMOVE_POPUP;
}
export interface RemoveAllPopupAction extends Action {
  type: ActionEnum.REMOVE_ALL_POPUP;
}
export type PopupAction =
  | SetPopupAction
  | RemovePopupAction
  | RemoveAllPopupAction;
