import React, { useCallback, useEffect, useState } from 'react';
import App from '../app';
import { firebaseApp } from './firebaseConfig';
import AuthContext from '../../context/AuthContext';
import { getFirestore } from 'firebase/firestore';
import type { User } from 'firebase/auth';
import {
  getAuth,
  getIdToken,
  onAuthStateChanged,
  getIdTokenResult,
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import Role from '@shared/types/role';
import FirebaseAnalytics from '../app/analytics';
import { getAnalytics } from 'firebase/analytics';

const FirebaseContainer: React.FC = () => {
  const firestore = getFirestore(firebaseApp);
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);
  const analytics = getAnalytics(firebaseApp);
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const [role, setRole] = useState<Role | undefined>(undefined);
  const [uid, setUID] = useState<string | undefined>(undefined);
  const setNewRole = useCallback(
    (newRole: Role | undefined) => {
      setRole((currentRole) => {
        if (currentRole === undefined || currentRole === newRole) {
          return newRole;
        } else {
          setTimeout(() => {
            if (user) {
              getIdToken(user, true).then(() => {
                window.location.reload();
              });
            }
          }, 3000);
          return newRole;
        }
      });
    },
    [user],
  );

  useEffect(() => {
    onAuthStateChanged(auth, (newUser) => {
      if (newUser) {
        getIdTokenResult(newUser).then((idTokenResult) => {
          setNewRole(idTokenResult.claims.role as Role | undefined);
        });
      } else {
        setNewRole(undefined);
      }
      setUser(newUser);
      setUID(newUser?.uid || undefined);
    });
  }, [setUser, auth, setNewRole]);

  return (
    <AuthContext.Provider
      value={{ firestore, auth, storage, analytics, user, role, uid }}
    >
      <FirebaseAnalytics />
      <App />
    </AuthContext.Provider>
  );
};

export default FirebaseContainer;
